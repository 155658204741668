<template>
  <analy :isCityUnpackAnaly="true" />
</template>
<script>
import analy from '../store-unpack/analy'
export default {
  components: {
    analy
  }
}
</script>
