<template>
  <div class="layout_common store-unpack_analy">
    <div class="main_box">
      <div class="main_box_con">
        <searchHead
          compType="store-unpack-analy"
          :isCityUnpackAnaly="isCityUnpackAnaly"
          :storeInfo="storeInfo"
          @search="search"
          @reset="search"
          @rankClick="isShow = true"
        />
        <ul class="echarts" v-loading="loading">
          <li>
            <div class="title">设备类别</div>
            <pieEcharts
              id1="analy_pie_box1"
              id2="analy_pie_echart1"
              :propData="deviceData"
            />
          </li>
          <li>
            <div class="title">问题归类</div>
            <pieEcharts
              id1="analy_pie_box2"
              id2="analy_pie_echart2"
              :propData="faultData"
            />
          </li>
          <li>
            <div class="title">责任归属</div>
            <pieEcharts
              id1="analy_pie_box3"
              id2="analy_pie_echart3"
              :propData="dutyData"
            />
          </li>
          <li>
            <div class="title">不良原因</div>
            <pieEcharts
              id1="analy_pie_box4"
              id2="analy_pie_echart4"
              :propData="reasonData"
            />
          </li>
        </ul>
      </div>
    </div>
    <!-- 商家发货排名 -->
    <rank :isShow="isShow" @close="isShow = false" @selectStore="selectStore" />
  </div>
</template>
<script>
import searchHead from '@/views/data-center/components/searchHead'
import rank from '@/views/data-center/components/rank'
import pieEcharts from '@/views/data-center/components/pieEcharts'
import { problemAnalysis } from '@/api'
// import { formatDate, diffDate } from '@/utils/common'
export default {
  components: {
    searchHead,
    pieEcharts,
    rank
  },
  props: {
    // 标识是否是城市开箱不良的问题分析页面
    isCityUnpackAnaly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      isShow: false,
      startTime: '',
      endTime: '',
      level: '',
      cityId: '',
      storeId: '',
      storeName: '',
      storeInfo: {},
      timeType: 3,
      dimensional: 2,
      deviceData: [],
      faultData: [],
      dutyData: [],
      reasonData: []
    }
  },
  mounted () {
    const {
      timeType,
      startTime,
      endTime,
      time,
      level,
      cityId,
      storeId
    } = this.$route.query
    this.timeType = +timeType
    this.storeId = storeId
    // 表示是城市开箱不良的问题分析页面
    if (this.isCityUnpackAnaly) {
      this.startTime = time
      this.endTime = time
      this.level = level
      this.cityId = cityId
    } else {
      this.startTime = startTime
      this.endTime = endTime
    }
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        cycleType: 1,
        timeType: this.timeType,
        startTime: this.startTime,
        endTime: this.endTime,
        dimensional: this.dimensional,
        level: this.level,
        cityId: this.cityId,
        storeId: this.storeId
      }
      this.loading = true
      problemAnalysis(params).then(res => {
        this.loading = false
        const { duty, category, problem, poorUnpacking } = res.data
        this.deviceData = category
        this.faultData = problem
        this.dutyData = duty
        this.reasonData = poorUnpacking
      })
    },
    // 选择的商家
    selectStore (val) {
      this.storeId = val.storeId
      this.storeName = val.storeName
      this.storeInfo = {
        storeId: this.storeId,
        storeName: this.storeName
      }
    },
    search (val) {
      this.curPage = 1
      const {
        dimensional,
        timeType,
        startTime,
        endTime,
        level,
        cityId,
        storeId
      } = val
      this.dimensional = dimensional
      this.timeType = timeType
      this.startTime = startTime
      this.endTime = endTime
      this.level = level
      this.cityId = cityId
      this.storeId = storeId
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.store-unpack_analy {
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    width: 50%;
    height: 280px;
    margin-top: 20px;
    .title {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      line-height: 40px;
      background-color: #eee;
    }
  }
}
</style>
